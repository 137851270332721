import { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { toaster } from '@ui/dialogs/ToastErrorMessage';
import SpaceAPI from '@api/spaceAPI';

const useSpaces = (isAuthenticated: boolean) => {
  const spaceAPI = isAuthenticated ? SpaceAPI.getInstance() : null;

  const [currentSpaceId, setCurrentSpaceId] = useState<string | null>(null);
  const [defaultSpaceId, setDefaultSpaceId] = useState<string | null>(null);

  const { data: spaces, refetch: refetchSpaces } = useQuery({
    queryKey: ['spaces'],
    queryFn: () => spaceAPI?.getSpaces(),
    enabled: isAuthenticated
  });

  const onSpaceChange = async (spaceId: string, update?: boolean) => {
    let spaceList = spaces;
    if (update) {
      const updatedSpaces = await refetchSpaces();
      spaceList = updatedSpaces.data;
    }
    const changedSpace = spaceList?.find((space) => space.id === spaceId);

    if (changedSpace) {
      localStorage.setItem('currentSpaceId', spaceId);
      setCurrentSpaceId(spaceId);
      toaster.info({
        title: 'Space Changed!',
        text: `The current space was changed to "${changedSpace.name}".`
      });
    }
  };

  const onCreateSpace = async (name: string): Promise<boolean> => {
    const newSpace = await spaceAPI?.createNewSpace(name);
    if (newSpace) {
      await onSpaceChange(newSpace.id, true);
      toaster.success({
        title: 'Space Created!',
        text: `A new space "${newSpace.name}" has been created.`
      });
      return true;
    }
    return false;
  };

  const onDeleteSpace = async (id: string): Promise<boolean> => {
    const response = await spaceAPI?.deleteSpace(id);
    if (response?.success) {
      const removedSpace = spaces?.find((space) => space.id === id);
      toaster.success({
        title: 'Space Deleted!',
        text: `The space "${removedSpace?.name}" has been deleted.`
      });
      if (removedSpace?.type === 'Intercom') {
        localStorage.removeItem('intercomToken');
      }
      if (id === currentSpace?.id) {
        await onSpaceChange(defaultSpaceId!, true);
      } else {
        await refetchSpaces();
      }
      return true;
    } else {
      toaster.error({
        title: 'Deletion Failed!',
        text: 'Failed to delete the space'
      });
      return false;
    }
  };

  const setCurrentSpace = () => {
    const spaceId = localStorage.getItem('currentSpaceId');
    const spaceById = spaces!.find((space: any) => space.id === spaceId)?.id;
    const defaultSpaceId = spaces!.find(
      (space: any) => space.name === 'Default'
    )!.id;
    setCurrentSpaceId(spaceById || defaultSpaceId);
    setDefaultSpaceId(defaultSpaceId);
  };

  useEffect(() => {
    if (spaces?.length) {
      setCurrentSpace();
    }
  }, [spaces]);

  const { data: currentSpace, refetch: refetchCurrentSpace } = useQuery({
    queryKey: ['spaces', currentSpaceId],
    queryFn: () => spaceAPI?.getSpace(currentSpaceId!),
    enabled: isAuthenticated && !!currentSpaceId
  });

  const dataSchema = useMemo(() => {
    if (currentSpace?.dataSchema) {
      // create object of fields keys
      const fields = currentSpace.dataSchema.fields.reduce((acc, curr) => {
        return { ...acc, [curr.special]: curr.key };
      }, {});

      return { textField: currentSpace.dataSchema.textField, ...fields };
    }
    return {};
  }, [currentSpace?.dataSchema]);

  const fileFields = useMemo(() => {
    if (currentSpace?.dataSchema) {
      return currentSpace.dataSchema.fields.map((field) => field.title);
    }
    return [];
  }, [currentSpace?.dataSchema]);

  return {
    spaces: spaces || [],
    refetchSpaces,
    currentSpace,
    refetchCurrentSpace,
    dataSchema,
    fileFields,
    onCreateSpace,
    onSpaceChange,
    onDeleteSpace
  };
};

export default useSpaces;
